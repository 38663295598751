import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./router";
import store from "./store.js";
import "./assets/application.js";
import GAuth from "vue3-google-oauth2";
import mitt from "mitt";
import InstantSearch from "vue-instantsearch/vue3/es";
import WaveUI from "wave-ui";
import "wave-ui/dist/wave-ui.css";
import ElementPlus from "element-plus";
import Fr from "element-plus/es/locale/lang/fr";
import "element-plus/dist/index.css";
import PrimeVue from "primevue/config";
import VueClickAway from "vue3-click-away";

const eventBus = mitt();
const gAuthOptions = {
  clientId:
    "600088063735-c78om60tq4vrq4hkr3mvuq34ncmdgf1l.apps.googleusercontent.com",
  scope: "email profile",
  prompt: "consent",
  fetch_basic_profile: true,
};

const app = createApp(App);

new WaveUI(app, {
  colors: { mystidia: "#F87171", font: "#374151" },
});

app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.config.globalProperties.eventBus = eventBus;
app
  .use(router)
  .use(store)
  .use(ElementPlus, { locale: Fr })
  .use(PrimeVue)
  .use(GAuth, gAuthOptions)
  .use(InstantSearch)
  .use(VueClickAway)
  .use(require('vue3-shortkey'))// raccoucis clavier
  .mount("#app");
