import axios from 'axios';
import router from '../router'; // adjust the path to your router file
import Cookies from 'js-cookie';
import store from "../store";
import { ElNotification } from 'element-plus'

const nodeEnv = process.env.NODE_ENV;
// Create an Axios instance
const axiosIns = axios.create({
  // Your API base URL and other configurations
  baseURL: nodeEnv == 'development' ? 'http://localhost:8000/api' : nodeEnv == 'production' ? 'https://backend.mystidia-exams.com/api' : '',
  //baseURL: 'https://backend.mystidia-exams.com/api',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': Cookies.get('access_token') ? 'Bearer ' + Cookies.get('access_token') : ''
  }
});


let isSessionExpired = false;  // Flag to track session expiration

// Add a response interceptor
axiosIns.interceptors.response.use(
  (response) => {
    // Return the response if successful
    return response;
  },
  (error) => {
    // Check if the error is a 401
    if (error.response && error.response.status === 401) {
      
      if (!isSessionExpired) {
        isSessionExpired = true;  // Set the flag to true to prevent multiple notifications
        //console.log('Session expired')
        // Handle session expiration
        Cookies.remove('access_token');
        store.commit('set_token', null);
        store.commit('set_islogin', false);
        store.commit('set_image', null);
        store.commit('set_darkMode', false);
        store.commit('set_subscriptions', []);
        store.commit("set_subscriptionsModule", []);
        //store.commit('set_subscribeNow', null);
        store.state.subscribeNow = null;
        store.commit('set_user', null);

        // Show notification once
        ElNotification({
          title: 'Expiration de session',
          message: 'Comme notre site web n\'accepte qu\'une seule connexion à la fois pour le même compte, votre session n\'est plus valide car une nouvelle connexion a été détectée.',
          duration: 0,
          type: 'warning',
          position: 'bottom-right',
        });

        // Redirect to login page
        router.push('/login');
      }
      
      // Stop the request by rejecting the promise
      return Promise.reject(new Error('Session expired. Further requests stopped.'));
    }
    
    // If it's not a 401 error, reject the promise with the error
    return Promise.reject(error);
  }
);

export default axiosIns;